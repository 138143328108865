<template>
	<div style="margin:35px;color: #000">
		<section class="pdf-item">

			<b-row>
				<b-col cols="12" class="text-center mb-1 font-weight-bold">{{station.title}}</b-col>
			</b-row>
			<b-row>
				<b-col cols="12" class="text-center mb-2 font-weight-bold">{{station.data.station_name}}</b-col>
			</b-row>
			<b-row> 
				<b-col cols="12" class="mr-1 ml-1">
					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							Profile 
						</b-col>
					</b-row>

					<b-row>
						<b-col cols="6"> 
							<b-row>
								<b-col cols="4"> 
									Station ID:  
								</b-col>
								<b-col cols="8"> 
									{{station.data.station_wmo_id}}  
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Wigos ID:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.wigos_id}}  
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Name:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.station_name}}  
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Latitude:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.current_latitude}}  
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Longitude:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.current_longitude}}  
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Elevasi:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.current_elevation}} m
								</b-col>
							</b-row> 
						</b-col>

						<b-col cols="6" > 
							<b-row>
								<b-col cols="4" > 
									Time Zone:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.timezone}}
								</b-col>
							</b-row>  
							<b-row>
								<b-col cols="4" > 
									Balai:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.region_description}}
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Provinsi:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.propinsi_name}}
								</b-col>
							</b-row> 
							<b-row>
								<b-col cols="4" > 
									Kabupaten:  
								</b-col>
								<b-col cols="8" > 
									{{station.data.kabupaten_name}}
								</b-col>
							</b-row> 
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							USAGE 
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12">
							<!-- <b-table
								:items="usageItems"
								:fields="usageFields"
								small
							></b-table> -->

							<table class="table-report-metadata"  style="margin-top:10px" border="0">
								<thead>
									<tr>
										<td rowspan="2" style="width:200px;">USAGE</td>
										<td colspan="2" style="border-bottom:none !important">DATE</td>
										<td rowspan="2" style="width:150px;">STATUS</td>
										<td rowspan="2" style="width:300px;">HISTORICAL NOTES</td>
									</tr>
									<tr >
										<td style="border-top:none; width:170px;">Established</td>
										<td style="border-top:none; border-right:none !important; width:180px;">Closed</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(raw, indexRaw) in usageItems" :key="indexRaw">
										<td style="border-top:none;text-align:center;">{{raw['usageName']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['usageStartDate']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['usageEndDate']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['usageStatus']}}</td>
										<td style="border-top:none;text-align:center;"></td>
									</tr>
								</tbody>
							</table>
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							GEOGRAPHY 
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12">
							<table class="table-report-metadata"  style="margin-top:10px" border="0">
								<thead>
									<tr>
										<td colspan="1" style="width:200px;">LATITUDE</td>
										<td colspan="1" style="width:200px;">LONGITUDE</td>
										<td colspan="1" style="width:200px;">ELEVATION</td>
										<td colspan="1" style="width:200px;">VALID FROM</td>
										<td colspan="1" style="width:200px;">VALID TO</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(raw, indexRaw) in geographyItems" :key="indexRaw">
										<td style="border-top:none;text-align:center;">{{raw['geography_latitude']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['geography_longitude']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['geography_elevation']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['geography_valid_from']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['geography_valid_to']}}</td>
									</tr>
								</tbody>
							</table>
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							PHOTOS 
						</b-col>
					</b-row>

					<!-- <b-row class="h-100">
						<b-col sm="4" class="mb-1" v-for="photo in sortedPhotos" :key="photo.id" @click="selectData(photo)">
							<div :class="{ 'photo-item': true, 'selected': photo.id == selected.id }">
								<b-img
									:src="photo.photo_data"
									fluid-grow
								/>
							</div>
						</b-col>
					</b-row> -->

					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							ELEMENTS 
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12">
							<table class="table-report-metadata"  style="margin-top:10px" border="0">
								<thead>
									<tr>
										<td colspan="1" style="width:50px;">NO</td>
										<td colspan="1" style="width:300px;">FORM</td>
										<td colspan="1" style="width:300px;">ELEMENT</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(raw, indexRaw) in elementItems" :key="indexRaw">
										<td style="border-top:none;text-align:center;">{{indexRaw + 1}}</td>
										<td style="border-top:none;text-align:center;">{{raw['ae_form_type']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['ae_label']}}</td>
									</tr>
								</tbody>
							</table>
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12" class="text-center font-weight-bold" style="background-color: #bfbfbf"> 
							OBSERVER 
						</b-col>
					</b-row>

					<b-row class="mt-1">
						<b-col cols="12">
							<table class="table-report-metadata"  style="margin-top:10px" border="0">
								<thead>
									<tr>
										<td colspan="1" style="width:50px;">NO</td>
										<td colspan="1" style="width:300px;">NIP</td>
										<td colspan="1" style="width:300px;">NAMA</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(raw, indexRaw) in observerItems" :key="indexRaw">
										<td style="border-top:none;text-align:center;">{{raw['no']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['observer_nip']}}</td>
										<td style="border-top:none;text-align:center;">{{raw['observer_name']}}</td>
									</tr>
								</tbody>
							</table>
						</b-col>
					</b-row>

				</b-col>
			</b-row>
			
		</section>
	</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BTable, BTr, BTh, BTd
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
// import store from '@/store/index'
// import metadata from "@/api/metadata";
// import useJwt from '@/auth/jwt/useJwt'
// import VueHtml2pdf from "vue-html2pdf";

export default {
	components: {
		BCardCode,
		VueGoodTable,
		BAvatar,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BRow,
		BCol,
		BCard,
		BButton,
		BTable,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
		BTd,
		BTr,
		BTh,
	},
	props: ["dataPDF"],
	data() {
		return {
			station: null,
			observerItems: [],
			observerFields: [{ key: 'no', label: 'No.'}, { key: 'observer_nip', label: 'NIP'}, { key: 'observer_name', label: 'Name'}],
			usageItems: [],
			// usageFields: [
			// 	{ key: 'usage', label: 'USAGE'}, 
			// 	{ key: 'start_date', label: 'From'}, 
			// 	{ key: 'end_date', label: 'To'},
			// 	{ key: 'status', label: 'Status'},
			// 	{ key: 'historical_notes', label: 'Historical Notes'}],
			geographyItems: [],
			geographyFields: [
				{ key: 'latitude', label: 'Latitude'}, 
				{ key: 'longitude', label: 'Longitude'}, 
				{ key: 'elevation', label: 'Elevation'},
				{ key: 'valid_from', label: 'Valid From'},
				{ key: 'valid_to', label: 'Valid To'}],
			elementItems: [],
			elementFields: [
				{ key: 'no', label: 'No'}, 
				{ key: 'element', label: 'Element'}],
			photos: []

		}
	},
	
	methods: {
		
	},

	computed: {
		sortedPhotos() {
			return this.photos.sort((a, b) => -(a.id.localeCompare(b.id)));
		},
	},

	mounted() {
		this.station = this.dataPDF
		this.elementItems = this.station.data['BmkgSatu.metadata.aelement.IAelement']['aelement_data']
		console.log('station: ', this.station, this.usageItems, this.elementItems)
		if (this.station.observer.data.items.length > 0 ) {
			for (var i = 0 ; i < this.station.observer.data.items.length; i++) {
				let observer = {
					no : i+1,
					observer_nip: this.station.observer.data.items[i].observer_nip,
					observer_name: this.station.observer.data.items[i].observer_name
				}
				this.observerItems.push(observer)
			}
		}

		if (this.station.usages.items && this.station.usages.items.length > 0) {
			for (let usage of this.station.usages.items) {
				
				let behavUsage = usage["BmkgSatu.metadata.station.behavusage.IBehavStationUsage"]
				// console.log('behavUsage', usage)
				let row = {
					usageName: behavUsage.usage_name,
					usageStartDate: behavUsage.usage_start_date ? behavUsage.usage_start_date.split('T')[0] : '',
					usageEndDate: behavUsage.usage_end_date ? behavUsage.usage_start_date.split('T')[0] : '',
					usageStatus: behavUsage.usage_status ? "Active" : "InActive"
				}
				this.usageItems.push(row)
			}
			
		}

		if (this.station.geography.items && this.station.usages.items.length > 0) {
			for (let geography of this.station.geography.items) {
				let row = {
					geography_latitude: geography.geography_latitude,
					geography_longitude: geography.geography_longitude,
					geography_elevation: geography.geography_elevation,
					geography_valid_from: geography.geography_valid_from ? geography.geography_valid_from.split('T')[0] : '',
					geography_valid_to: geography.geography_valid_to ? geography.geography_valid_to.split('T')[0] : ''
				}
				this.geographyItems.push(row)
			}
			
		}

	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
	.vgt-table thead th.theadc {
		background-color: #f7fafc !important;
	}


	.table-report-metadata  tbody > tr > td:last-child {
		border-right: solid 1px rgb(90, 90, 90);
	}


	.table-report-metadata  tbody > tr > td {
		padding: 5px !important;
		margin: 0px;
		text-align: center;
		color:#000;
		border-left:solid 1px rgb(90, 90, 90);
		border-bottom:solid 1px rgb(90, 90, 90);
	}

	.table-report-metadata > thead > tr > td:last-child{
		border-right: solid 1px rgb(90, 90, 90) !important;
	}

	.table-report-metadata > thead > tr > td {
		background: #ffffff;
		color: #000000;
		border-left: solid 1px rgb(90, 90, 90) !important;
		border-top: solid 1px rgb(90, 90, 90) !important;
		border-bottom: solid 1px rgb(90, 90, 90) !important;
	}

	.table-report-metadata > thead > tr > td {
		text-align: center;
		font-weight: bold;
		padding: 5px !important;
	}

</style>
