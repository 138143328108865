<template>
    <a-spin :spinning="spinningLoadingPage">
        <div>
            <b-card title="Metadata">
                <div class="custom-search ml-1 mr-1">
                    <!-- advance search input -->
                    <b-row>
                        <b-col cols="11">
                            <b-row class="mb-1" style="background-color: #ecf3f8; border-radius: 5px;">
                                <b-col cols="11" class="mt-1">
                                    <b-row>
                                        <b-col md="4">
                                            <b-form-group label-cols="10" label-cols-lg="3" label="Usage :" label-for="search-usage">
                                                <v-select
                                                    id="search-usage"
                                                    v-on:input="getSelectedUsage(`${usageSelected.value}`)"
                                                    v-model="usageSelected"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="usageOptions"
                                                    class="select-size-sm"
                                                    style="margin-top: -2px; background: white;"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4">
                                            <b-form-group label-cols="10" label-cols-lg="3" label="Province :" label-for="search-province">
                                                <v-select
                                                    id="search-province"
                                                    v-on:input="getSelectedPropinsi(`${propinsiSelected.value}`)"
                                                    v-model="propinsiSelected"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="propinsiOptions"
                                                    class="select-size-sm"
                                                    style="margin-top: -2px; background: white;"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4">
                                            <b-form-group label-cols="10" label-cols-lg="3" label="Search :" label-for="search-search">
                                                <b-form-input id="search-search" v-model="searchText" placeholder="Search" type="text" class="d-inline-block" v-on:keyup="isStationID" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>

                                <b-col cols="1" class="mt-1 text-center">
                                    <b-button variant="primary" class="btn-icon" v-on:click="searchMetadata" :disabled="!enabledSearch">
                                        <feather-icon icon="SearchIcon" />
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col class="pl-1" cols="1">
                            <b-row class="mb-1" style="background-color: #ecf3f8; border-radius: 5px; margin-left: 5px;">
                                <b-col class="mt-1 mb-1" style="justify-content: center; display: flex;">
                                    <b-button id="add-metadata" variant="primary" class="btn-icon" v-on:click="addMetadata">
                                        <feather-icon icon="PlusIcon" />
                                    </b-button>
                                    <b-tooltip target="add-metadata" triggers="hover">
                                        Tambah Stasiun Baru
                                    </b-tooltip>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>

                <!-- table -->
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :isLoading="isLoading"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm,
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage: pageLength,
                        setCurrentPage: paginationCurrentPage,
                    }"
                    @on-row-click="onRowClick"
                    @on-cell-click="onCellClick"
                    @on-page-change="onPageChange"
                    theme="polar-bear"
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'summary'" class="text-nowrap">
                            <a href="#">preview</a>
                        </span>

                        <span v-else-if="props.column.field === 'station_wmo_id'" class="text-nowrap">
                            <a v-if="searchByUsage" href="#">{{ props.row.usage_station_id }}</a>

                            <a v-else-if="props.row.station_wmo_id == 0 || props.row.station_wmo_id == null" href="#">{{ props.row.is_poshujan ? props.row.pos_hujan_id : props.row.station_id }}</a>

                            <a v-else-if="props.row.station_wmo_id != 0" href="#">{{ props.row.station_wmo_id }}</a>

                            <a v-else href="#">{{ props.row.station_id }}</a>
                        </span>
                    </template>
                </vue-good-table>

                <client-only>
                    <vue-html2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="false"
                        :preview-modal="true"
                        :paginate-elements-by-height="9500"
                        :filename="filePdfName"
                        :pdf-quality="3"
                        :manual-pagination="false"
                        pdf-format="a4"
                        v-bind:pdf-orientation="REPORT_ORIENTATION"
                        @beforeDownload="beforeDownload($event)"
                        @progress="onProgress($event)"
                        @hasStartedGeneration="hasStartedGeneration()"
                        @hasGenerated="hasGenerated($event)"
                        ref="html2Pdf"
                        pdf-content-width="680px"
                        :html-to-pdf-options="htmlToPdfOptions"
                    >
                        <section slot="pdf-content">
                            <report-stasiun v-if="STORE_REPORT != null" :dataPDF="STORE_REPORT"> </report-stasiun>
                        </section>
                    </vue-html2pdf>
                </client-only>
            </b-card>
        </div>

        <b-modal id="modal-add-station" ref="modal-add-station" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close" centered title="Form Tambah Stasiun Baru" no-close-on-backdrop :header-bg-variant="headerBgVariant">
            <b-row>
                <b-col cols="6">
                    <b-row class="mt-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Balai * :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <v-select id="search-balai" v-model="s_balai_selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="s_balai_options" class="select-size-sm" style="margin-top: -2px; background: white;" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Provinsi * :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <v-select id="search-provinsi" v-model="s_propinsi_selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="s_propinsi_options" class="select-size-sm" style="margin-top: -2px; background: white;" />
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Kabupaten/Kota * :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <v-select id="search-kabupaten" v-model="s_kabupaten_selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="s_kabupaten_options" class="select-size-sm" style="margin-top: -2px; background: white;" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Nama * :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <b-form-input id="s_name" class="" v-model="s_name"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Time Zone :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <b-form-select v-model="s_time_zone" id="select-time-zone" :options="options.timezone"></b-form-select>
                        </b-col>
                    </b-row>
                </b-col>
                <!-- <b-col cols="1"> 
					
				</b-col> -->
                <b-col cols="6">
                    <b-row class="mt-1 ml-1">
                        <b-col cols="5" class="">
                            <div class="label-entry1 font-weight-bolder">
                                Type :
                            </div>
                        </b-col>
                        <b-col cols="7" class="m-auto pr-1 pl-0">
                            <b-form-select v-model="s_type_mkg" id="select-type-mkg" :options="options.mkg_type"></b-form-select>
                        </b-col>
                    </b-row>
                    <div v-if="s_type_mkg && s_type_mkg.trim() != ''">
                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Date Established :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-datepicker id="valid-from" v-model="s_valid_from" :readonly="s_valid_from" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Date Closed :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-datepicker id="valid-end" v-model="s_valid_end" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    WMO ID :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-input id="s_station_id" class="" v-model="s_station_id"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Wigos ID :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-input id="s_wigos_id" class="" v-model="s_wigos_id"></b-form-input>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    ICAO :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-input id="s_icao" class="" v-model="s_icao"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Operation Hours :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-input id="s_operating_hour" class="" type="number" v-model.number="s_operating_hour"></b-form-input>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Op.Hours - Start :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-select v-model="s_hour_start" id="select-start" :options="options.time_type"></b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Op.Hours - Mid End :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-select v-model="s_mid_hour_end" id="select-start" :options="options.time_type"></b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Op.Hours - Mid Start :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-select v-model="s_mid_hour_start" id="select-start" :options="options.time_type"></b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 ml-1">
                            <b-col cols="5" class="">
                                <div class="label-entry1 font-weight-bolder">
                                    Op.Hours - End :
                                </div>
                            </b-col>
                            <b-col cols="7" class="m-auto pr-1 pl-0">
                                <b-form-select v-model="s_hour_end" id="select-start" :options="options.time_type"></b-form-select>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-button size="md" @click="onCancel"> Cancel </b-button>
                <b-button size="md" variant="gradient-primary" @click="onSubmit"> Submit </b-button>
            </template>
            <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
        </b-modal>
    </a-spin>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BTooltip, BModal, BFormDatepicker, BOverlay } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import metadata from "@/api/metadata";
import useJwt from "@/auth/jwt/useJwt";
import VueHtml2pdf from "vue-html2pdf";
import ReportStasiun from "./ReportStasiun.vue";
import DataStasiun from "@/api/store/DataMetadata";
import User from "@/api/userservice";
import vSelect from "vue-select";
import axios from "axios";
import Helper from "@/api/helper";
import error_handler from "@/api/error_handler";

export default {
    components: {
        BCardCode,
        VueGoodTable,
        BAvatar,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BRow,
        BCol,
        BCard,
        BButton,
        VueHtml2pdf,
        ReportStasiun,
        DataStasiun,
        vSelect,
        BTooltip,
        BModal,
        BFormDatepicker,
        BOverlay,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            showLoading: false,
            paginationCurrentPage: 1,
            pageLength: 10,
            dir: false,
            isLoading: true,
            columns: [
                {
                    label: "Station ID",
                    field: "station_wmo_id",
                    tdClass: "text-center",
                    thClass: "text-center theadc",
                },
                {
                    label: "Station Name",
                    field: "station_name",
                    tdClass: "text-left",
                    thClass: "text-center theadc",
                },
                {
                    label: "Province",
                    field: "propinsi_name",
                    tdClass: "text-center",
                    thClass: "text-center theadc",
                },
                {
                    label: "Summary",
                    field: "summary",
                    tdClass: "text-center",
                    thClass: "text-center theadc",
                },
            ],
            userType: "",
            ids: "",
            rows: [],
            searchTerm: "",
            searchText: "",
            searchByUsage: false,
            usageSelected: "",
            usageOptions: [
                { value: "", text: "-" },
                { value: "is_mkg", text: "MKG" },
                { value: "is_poshujan", text: "Pos Hujan" },
                { value: "is_kualitas_udara", text: "Kualitas Udara" },
                { value: "is_peralatan_otomatis", text: "AWS Center" },
                { value: "is_radar", text: "Radar" },
                { value: "is_percepatan_tanah", text: "Percepatan Tanah" },
                { value: "is_gempa", text: "Gempa" },
                { value: "is_bmkgentry", text: "BMKG Entry" },
            ],
            propinsiSelected: "",
            propinsiOptions: [{ value: "", text: "-" }],
            paramsStation: "",
            REPORT_ORIENTATION: "portrait",
            STORE_REPORT: null,
            spinningLoadingPage: false,
            filePdfName: "",
            htmlToPdfOptions: {
                margin: 17,
            },
            enabledSearch: false,
            headerBgVariant: "info",
            headerTextVariant: "light",
            footerBgVariant: "info",
            options: {
                time_type: [
                    { value: 0, text: "00:00" },
                    { value: 1, text: "01:00" },
                    { value: 2, text: "02:00" },
                    { value: 3, text: "03:00" },
                    { value: 4, text: "04:00" },
                    { value: 5, text: "05:00" },
                    { value: 6, text: "06:00" },
                    { value: 7, text: "07:00" },
                    { value: 8, text: "08:00" },
                    { value: 9, text: "09:00" },
                    { value: 10, text: "10:00" },
                    { value: 11, text: "11:00" },
                    { value: 12, text: "12:00" },
                    { value: 13, text: "13:00" },
                    { value: 14, text: "14:00" },
                    { value: 15, text: "15:00" },
                    { value: 16, text: "16:00" },
                    { value: 17, text: "17:00" },
                    { value: 18, text: "18:00" },
                    { value: 19, text: "19:00" },
                    { value: 20, text: "20:00" },
                    { value: 21, text: "21:00" },
                    { value: 22, text: "22:00" },
                    { value: 23, text: "23:00" },
                ],
                mkg_type: [
                    { value: "", text: "" },
                    { value: "M", text: "Meteorologi" },
                    { value: "K", text: "Klimatologi" },
                    { value: "G", text: "Geofisika" },
                ],
                timezone: ["+07:00", "+08:00", "+09:00"],
            },
            type_mkg: "",
            s_balai_selected: "",
            s_balai_options: [],
            s_propinsi_selected: "",
            s_propinsi_options: [],
            s_kabupaten_selected: "",
            s_kabupaten_options: [],
            s_name: "",
            s_time_zone: [],
            s_type_mkg: "",
            s_valid_from: null,
            s_valid_end: null,
            s_station_id: null,
            s_wigos_id: null,
            s_icao: null,
            s_operating_hour: null,
            s_hour_start: null,
            s_mid_hour_end: null,
            s_mid_hour_start: null,
            s_hour_end: null,
        };
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        },
    },
    created() {
        // this.$http.get('/good-table/advanced-search')
        // .then(res => { this.rows = res.data })
    },
    methods: {
        isStationID(event) {
            let text = this.searchText;
            if (text === "") {
                this.enabledSearch = true;
            }
            if (!isNaN(text)) {
                if (text.length >= 5) {
                    this.enabledSearch = true;
                    return event.preventDefault();
                } else if (text.length == 5) {
                    this.enabledSearch = true;
                } else {
                    this.enabledSearch = false;
                }
            } else {
                this.enabledSearch = true;
            }
        },

        isSearchTextEnabled(text) {
            if (text === "") {
                return true;
            }
            if (!isNaN(text)) {
                if (text.length >= 5) {
                    return true;
                } else if (text.length == 5) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },

        advanceSearch(val) {
            this.searchTerm = val;
        },
        onRowClick(params) {},

        beforeDownload($event) {},
        onProgress($event) {},
        hasStartedGeneration() {},
        hasGenerated($event) {},

        searchMetadata() {
            let usage = this.usageSelected !== null && this.usageSelected !== "" ? this.usageSelected.value : "";
            let propinsi = this.propinsiSelected !== null && this.propinsiSelected !== "" ? this.propinsiSelected.value : "";
            let search = this.searchText;
            let isUsage = false;

            this.paramsStation = "";

            if (usage && usage != "") {
                // this.paramsStation = "&"+usage+"=true"
                isUsage = true;
                this.paramsStation = "&usage_name=" + usage + "&usage_status=true";
                this.searchByUsage = usage == "MKG" ? false : true;
            } else {
                this.searchByUsage = false;
            }

            if (propinsi && propinsi != "") {
                this.paramsStation = this.paramsStation + "&propinsi_id=" + propinsi;
            }

            if (search && search != "") {
                let isStationName = isNaN(search);

                if (isStationName) {
                    this.paramsStation = this.paramsStation + "&station_name=" + search;
                } else {
                    this.paramsStation = this.paramsStation + "&station_wmo_id=" + search;
                }
            }

            this.paginationCurrentPage = 1;
            if (this.paramsStation != "") {
                this.rows = [];
                this.listAllStasiun(0, 10000, this.paramsStation, this.userType, this.ids, isUsage);
            } else {
                this.rows = [];
                this.listAllStasiun(0, 200, this.paramsStation, this.userType, this.ids, isUsage);
            }
        },

        onReportStasiun(path, station_id, station_name) {
            this.filePdfName = "Report " + station_name;
            this.STORE_REPORT = null;
            var report = {};
            report.title = "STASIUN REPORT";

            this.spinningLoadingPage = true;

            new Promise(async (resolve) => {
                report.observer = await User.getObserverbyStationId(station_id);

                const { data } = await DataStasiun.storeDataMetadata(path);
                report.data = data;

                if (data.items && data.items.length > 0) {
                    const { data } = await metadata.listMetadataUsage(path);
                    report.usages = data;
                }

                let geography = await metadata.listMetadataGeography(path);
                report.geography = geography.data;

                this.STORE_REPORT = report;

                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoadingPage = false;
                }, 1000);
                resolve(report);
            });
        },

        onCellClick(params) {
            if (params.column.field == "summary") {
                this.onReportStasiun(params.row.path, params.row.station_id, params.row.station_name);
            } else {
                this.$router.push({
                    name: "metadata-stationprofiles",
                    query: {
                        path: params.row.path,
                        content: params.row,
                    },
                });
            }
        },

        onPageChange(params) {
            this.paginationCurrentPage = params.currentPage;
            let startId = params.currentPage * params.currentPerPage;
            let endId = startId + 200;
            if (params.currentPage >= params.total && this.paramsStation == "") {
                this.listAllStasiun(startId, endId, this.paramsStation, this.userType, this.ids, false);
            }
        },

        getSelectedUsage(val) {
            // console.log('getSelectedUsage', val)
            this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false;
        },

        getSelectedPropinsi(val) {
            // console.log('getSelectedPropinsi', val)
            this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false;
        },

        async listAllPropinsi() {
            const { data: propinsi_data } = await metadata.getPropinsiList();
            let propinsiList = propinsi_data.sort((a, b) => {
                let fa = a.propinsi_name.toLowerCase();
                let fb = b.propinsi_name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            propinsiList.forEach((item) => {
                const propinsi_item = {
                    value: item.propinsi_id,
                    label: item.propinsi_name,
                };
                this.propinsiOptions.push(propinsi_item);
            });
        },

        async onPropinsiChange() {
            let propinsiid = this.propinsi;
            // console.log('propinsiid: ', propinsiid)
            const { data: kabupaten_data } = await metadata.getKabupatenListByPropinsi(propinsiid);
            kabupaten_data.forEach((item) => {
                const kabupaten_item = {
                    value: item.kabupaten_id,
                    text: item.kabupaten_name,
                };
                this.options_kabupaten.push(kabupaten_item);
            });
        },

        onKabupatenChange() {},

        async listAllStasiun(startId, endId, options, userType, ids, isUsage) {
            this.isLoading = true;
            try {
                const { data } = await metadata.getAllStationWithParams(startId, endId, options, userType, ids, isUsage);

                if (!Array.isArray(data)) {
                    this.rows.push(...data.items);
                } else {
                    data.forEach(function (item, index) {
                        let path = item.path;
                        if (path) {
                            item.path = path.split("usage")[0];
                        }
                    });
                    this.rows.push(...data);
                }
                // if (userType == 'root') {
                // 	this.rows.push(...data.items)
                // }
                // else {
                // 	this.rows.push(...data)
                // }

                // this.rows.push(...data.items)

                this.rows.sort(function (a, b) {
                    return a.station_id - b.station_id;
                });
            } catch (error) {
                console.log("error", error);
                // if (error.hasOwnProperty('response') && error.response.hasOwnProperty('status') && error.response.status != undefined && error.response.status === 401) {
                // this.$swal({
                // 		title: "Sesi anda telah habis.",
                // 		text: "Logout dan silahkan login kembali",
                // 		icon: "warning",
                // 		confirmButtonText: "OK",
                // 		allowOutsideClick: false,
                // 		allowEscapeKey: false,
                // 		customClass: {
                // 			confirmButton: "btn btn-primary",
                // 		},
                // 		buttonsStyling: false,
                // 	}).then((result) => {
                // 		localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                // 		localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                // 		localStorage.removeItem('userData')
                // 		this.$router.push({ name: 'auth-login' })
                // 	});
                // }
            }

            this.isLoading = false;
        },

        async listManageUsage() {
            // console.log('listUsage')
            try {
                const { data } = await metadata.getListManageUsage();
                this.usageOptions = [];
                let usagesList = data.items.sort((a, b) => {
                    let fa = a.manage_usage_name.toLowerCase();
                    let fb = b.manage_usage_name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                for (var i = 0; i < usagesList.length; i++) {
                    if (usagesList[i].manage_usage_status) {
                        const row = {
                            value: usagesList[i].manage_usage_name,
                            label: usagesList[i].manage_usage_name,
                        };
                        this.usageOptions.push(row);
                    }
                }

                this.usageSelected = "";
            } catch (e) {
                console.log(e);
            }
        },

        addMetadata() {
            this.$refs["modal-add-station"].show();
            if (this.s_balai_options.length < 1) {
                this.listAllBalai();
            }
        },

        onCancel() {
            this.$refs["modal-add-station"].hide();
        },

        async onSubmit() {
            let post_data = {
                "@type": "BmkgStation",
                station_name: this.s_name,
                station_platform_name: this.s_name,
                kabupaten_id: this.s_kabupaten_selected.value,
                kabupaten_code: this.s_kabupaten_selected.code,
                kabupaten_name: this.s_kabupaten_selected.label,
                propinsi_id: this.s_propinsi_selected.value,
                propinsi_code: this.s_propinsi_selected.code,
                propinsi_name: this.s_propinsi_selected.label,
                region_id: this.s_balai_selected.value,
                region_description: this.s_balai_selected.label,
                timezone: this.s_time_zone,
                station_icao: this.s_icao,
                wigos_id: this.s_wigos_id,
                station_wmo_id: this.s_station_id,
                sync_metastation: 1,
            };

            if (this.s_type_mkg == "M" || this.s_type_mkg == "K" || this.s_type_mkg == "G") {
                post_data["is_mkg"] = true;
                post_data["alias_station_id"] = this.s_station_id;
                post_data["BmkgSatu.metadata.mkg.IMKG"] = {
                    wmo_id: this.s_station_id,
                    station_type_mkg: this.s_type_mkg,
                    station_operating_hours: this.s_operating_hour,
                    station_hour_start: this.s_hour_start,
                    station_hour_end: this.s_hour_end,
                    station_mid_hour_start: this.s_mid_hour_start,
                    station_mid_hour_end: this.s_mid_hour_end,
                    station_valid_from: this.s_valid_from,
                    station_valid_to: this.s_valid_end,
                };
            }

            console.log("post_data", post_data);
            this.showLoading = true;
            let url = Helper.getBaseUrl() + "/" + this.s_balai_selected.value + "/" + this.s_propinsi_selected.value + "/" + this.s_kabupaten_selected.value + "/";
            this.spinningLoadingPage = true;
            await axios
                .post(url, post_data, Helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.$swal({
                            title: "Input Stasiun Baru Berhasil!",
                            text: "Input Stasiun Baru",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });

                        if (this.s_type_mkg == "M" || this.s_type_mkg == "K" || this.s_type_mkg == "G") {
                            let id = response.data["@id"];
                            let path = id.split("bmkgsatu")[1];
                            this.onRequestAddUsage(path, "MKG");
                        }
                    }
                    this.showLoading = false;
                    this.spinningLoadingPage = false;
                    this.$refs["modal-add-station"].hide();
                    this.clearModalAddStation();
                })
                .catch((error) => {
                    console.log("error add stastuib", error);
                    this.spinningLoadingPage = false;
                    this.showLoading = false;
                    this.$refs["modal-add-station"].hide();
                    this.clearModalAddStation();
                    error_handler(this, error, "Metadata", "Input Data Error");
                });
            this.showLoading = false;
        },

        clearModalAddStation() {
            this.s_balai_selected = "";
            this.s_propinsi_selected = "";
            this.s_kabupaten_selected = "";
            this.s_name = "";
            this.s_type_mkg = "";
            this.s_valid_from = null;
            this.s_valid_end = null;
            this.s_station_id = null;
            this.s_wigos_id = null;
            this.s_icao = null;
            this.s_operating_hour = null;
            this.s_hour_start = null;
            this.s_mid_hour_end = null;
            this.s_mid_hour_start = null;
            this.s_hour_end = null;
        },

        onRequestAddUsage(path_station, usage_name) {
            new Promise(async (resolve) => {
                // console.log('this.path', this.path)
                let path = path_station + "/usage";
                let post_data = {
                    "@type": "MetaStationUsage",
                    "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                        usage_name: usage_name,
                        usage_station_id: "-1",
                        usage_status: true,
                    },
                };
                this.showLoading = true;
                const { data } = await metadata.requestMetadataUsage(path, post_data);
                this.showLoading = false;
                console.log("data: ", data);

                let detail = {
                    usage_contact_name: null,
                    usage_contact_telephone: null,
                    usage_elevation: null,
                    usage_end_date: null,
                    usage_koordinator_station: null,
                    usage_latitude: null,
                    usage_longitude: null,
                    usage_name: "",
                    usage_start_date: null,
                    usage_station_id: "",
                    usage_station_name: null,
                    usage_status: true,
                    usage_wigos_id: "",
                };

                let row = {
                    value: usage_name,
                    label: usage_name,
                    status: true,
                    path: data["@id"],
                    detail: detail,
                };
                this.$emit("newUpdateUsage", row);
                resolve(data);
            });
        },

        async listAllBalai() {
            const { data: balai_data } = await metadata.getRegionList();
            let regionList = balai_data.sort((a, b) => {
                let fa = a.region_description.toLowerCase();
                let fb = b.region_description.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            regionList.forEach((item) => {
                const balai_item = {
                    value: item.region_id,
                    label: item.region_description,
                };
                this.s_balai_options.push(balai_item);
            });
        },

        async listPropinsi(balai) {
            const { data: propinsi_data } = await metadata.getPropinsiListByRegion(balai.value);
            console.log("propinsi_data", propinsi_data);
            let propinsiList = propinsi_data.sort((a, b) => {
                let fa = a.propinsi_name.toLowerCase();
                let fb = b.propinsi_name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            this.s_propinsi_options = [];
            this.s_kabupaten_options = [];
            propinsiList.forEach((item) => {
                const propinsi_item = {
                    value: item.propinsi_id,
                    label: item.propinsi_name,
                    code: item.propinsi_code,
                };
                this.s_propinsi_options.push(propinsi_item);
            });
        },

        async listKabupaten(propinsi) {
            const { data: kabupaten_data } = await metadata.getKabupatenListByPropinsi(propinsi.value);
            // console.log('kabupaten_data', kabupaten_data)
            let kabupatenList = kabupaten_data.sort((a, b) => {
                let fa = a.kabupaten_name.toLowerCase();
                let fb = b.kabupaten_name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            this.s_kabupaten_options = [];
            kabupatenList.forEach((item) => {
                const kabupaten_item = {
                    value: item.kabupaten_id,
                    label: item.kabupaten_name,
                    code: item.kabupaten_code,
                };
                this.s_kabupaten_options.push(kabupaten_item);
            });
        },
    },

    watch: {
        s_balai_selected(value) {
            console.log("s_balai_selected", value);
            if (value) {
                this.s_propinsi_selected = "";
                this.s_kabupaten_selected = "";
                this.listPropinsi(value);
            }
        },

        s_propinsi_selected(value) {
            console.log("s_propinsi_selected", value);
            this.s_kabupaten_selected = "";
            if (value) {
                this.listKabupaten(value);
            }
        },
    },

    mounted() {
        let userLogin = JSON.parse(localStorage.getItem("userLogin"));
        this.userType = userLogin.username;

        if (userLogin.hasOwnProperty("groupIds")) {
            this.ids = userLogin.groupIds;
        }

        this.usageOptions = [{ value: "", text: "-" }];
        this.listManageUsage();
        this.listAllPropinsi();

        this.rows = [];
        this.paramsStation = "";
        this.listAllStasiun(1, 200, this.paramsStation, this.userType, this.ids, false);
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
.vgt-table thead th.theadc {
    background-color: #f7fafc !important;
}

.modal-dialog {
    min-width: 1000px !important;
}
</style>
