const api_error_alert = (that, error, type="", title="Error") => {
  const error_text = {
    404: `Data ${type} tidak ditemukan`,
    409: `Data ${type} sudah ada`,
    412: `Pastikan tipe data input sudah sesuai format`,
    500: `Something has gone wrong on the web site's server but the server could not be more specific on what the exact problem is`,
  }
  if(error.response) {
    console.log(error.response);
    const { response } = error;

    that.$swal({
      title: title,
      text: error_text[response.status] ?? response.statusText,
      icon: "error",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
  }else if(error.request) {
    // The request was made but no response was received
    that.$swal({
      title: title,
      text: "Error, Pastikan Terhubung ke internet/jaringan yang stabil",
      icon: "error",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    console.log("request error", error.request);
  }else {
    // Something happened in setting up the request that triggered an Error
    that.$swal({
      title: title,
      text: "Error, Terjadi Kesalahan!",
      icon: "error",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    console.log("error:", error.message);
  }
};

export default api_error_alert;
